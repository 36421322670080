<template>
  <main>
    <img alt="Vue logo" src="./assets/logo.png" />
    <h1>Welcome to test page for Simple Client Deployment</h1>
  </main>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
main {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
